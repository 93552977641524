import request from './request'
// 登录
const url = 'https://yshzk.com/api'
// const url = 'http://192.168.110.42:8080'

export const login = params => {
  return request({
    url: '/api/skNewMedia/media/index/login',
    method: 'post',
    data: params
  });
};
// 注册
export const register = params => {
  return request({
    url: '/api/skNewMedia/media/index/register',
    method: 'post',
    data: params
  });
};
//  发布信息
export const addApi = params => {
  return request({
    url: '/api/skNewMedia/media/information/submitInformation/save',
    method: 'post',
    data: params
  });
};
// 获取支付二维码
export const getPayCode = params => {
  return url + '/skNewMedia/media/wechat/memberZx/getCode?accountId=' + params.accountId + '&paymentAmount=' + params.paymentAmount
};
//  查看是不是会员
export const queryIsMember = params => {
  return request({
    url: '/api/skNewMedia/media/wechat/member/queryIsMember',
    method: 'get',
    params: {
      ...params,
      noSpinning: true
    }
  });
};
// 获取用户信息
export const getUserInfo = params => {
  return request({
    url: '/api/skNewMedia/media/userAccount/getUserInfo?id=' + params.accountId,
    method: 'post'
  });
};

// 获取省市县
export const areaList = params => {
  return request({
    url: '/api/skNewMedia/media/area/list',
    method: 'get',
    params: params
  });
};
// 获取地址列表
export const areaGetList = params => {
  return request({
    url: '/api/skNewMedia/media/area/getList',
    method: 'get',
    params: params
  });
};
// 新增地址列表
export const areaSaveOrUpdate = params => {
  return request({
    url: '/api/skNewMedia/media/area/saveOrUpdate',
    method: 'post',
    data: params
  });
};
// 设置为默认
export const setDefault = params => {
  return request({
    url: '/api/skNewMedia/media/area/setDefault',
    method: 'get',
    params: params
  });
};
// 删除
export const areaDelete = params => {
  return request({
    url: '/api/skNewMedia/media/area/delete',
    method: 'get',
    params: params
  });
};

// 获取订单列表
export const orderGetList = params => {
  return request({
    url: '/api/skNewMedia/media/wechat/userGoodsOrder/getList',
    method: 'get',
    params: params
  });
};

// 获取订单支付二维码
export const getOrderPayCode = params => {
  return url + '/skNewMedia/media/wechat/userGoodsOrderZx/submitOrder?productid=' + params.productid + '&productname=' + params.productname + '&number=' + params.number + '&unitprice=' + params.unitprice + '&areaId=' + params.areaId + '&producttype=' + params.producttype + '&accountId=' + params.accountId + '&paymentAmount=' + params.paymentAmount + '&oldOrderno=' + params.oldOrderno
};
// 查看订单是不是已支付
export const queryOrder = params => {
  return request({
    url: '/api/skNewMedia/media/wechat/userGoodsOrder/queryOrderStatus',
    method: 'get',
    params: {
      ...params,
      noSpinning: true
    }
  });
};


